<script setup lang="ts">
import { Check, ChevronsUpDown } from 'lucide-vue-next'

import { PropType, ref, watch, type HTMLAttributes, } from 'vue'
import { cn } from '../lib/utils'
import { Button } from './button' 
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from './command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from './popover'

type Option = {
  label: string;
  value: unknown;
};

const props = defineProps({
  label: {
    type: String,
    default: undefined,
  },
  emptyLabel: {
    type: String,
    default: 'No items found.',
    validator: (value: string) => !!value,
  },
  placeHolder: {
    type: String,
    required: true,
  },
  options: {
    type: Array as PropType<Option[]>,
    required: true,
  },
  useBackground: {
    type: Boolean,
  },
  labelStyles: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: undefined,
    default: null,
  },
  class: {
    type: String as PropType<HTMLAttributes['class']>,
    default: ''
  }
});

const open = ref(false)
const value = ref<unknown>(null);
const emit = defineEmits(['update:modelValue']);

const handleSelect = (index: number) => {
  const option = props.options[index];
  value.value = option.label;
  emit('update:modelValue', option.value);
  open.value = false; 
}
</script>

<template>
  <Popover v-model:open="open">
    <PopoverTrigger :class="cn('w-full', props.class)">
      <Button
        variant="transparent"
        role="combobox"
        :class="cn('justify-between dark:text-white border border-gray-600 dark:hover:bg-gray-600 w-full', props.class)"
      >
        <p class="truncate">
          {{ value ? options.find((option) => option.label === value)?.label : placeHolder }}
        </p>
        <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
      </Button>
    </PopoverTrigger>
    <PopoverContent class="p-0 scroll-bar-thin">
      <Command>
        <CommandInput :placeholder="$t('common.search')" />
        <CommandEmpty>{{ emptyLabel }}</CommandEmpty>
        <CommandList>
          <CommandGroup>
            <CommandItem
              v-for="option, index in options"
              :key="`${option.value}`"
              :value="option.label"
              @select="handleSelect(index)"
            >
              <Check
                :class="cn(
                  'mr-2 h-4 w-4',
                  value === option.label ? 'opacity-100' : 'opacity-0',
                )"
              />
              {{ option.label }}
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </Command>
    </PopoverContent>
  </Popover>
</template>
