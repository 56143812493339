<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { withUseQuery } from '@wision/api';
import { useI18n } from 'vue-i18n';
import { useMutation } from '@tanstack/vue-query';
import { toast } from 'vue3-toastify';
import {
  WisionLogo,
  AvatarIcon,
  BankIdIcon,
  MoreIcon,
  ArrowIcon,
  Direction
} from '@wision/ui';
import EmailLoginForm from './EmailLoginForm.vue';
import SelectAccount from './SelectAccount.vue';

const { t } = useI18n();
const showLoginForm = ref(false);
const mouseX = ref<number>(0);
const mouseY = ref<number>(0);
const angle = ref<number>(180);
const spinning = ref(false);
const customers = ref<{ id: number; name: string }[]>();
const userFullName = ref<string>();
const pageY = ref(0);
const height = ref(0);
const spinIntervalId = ref();
const config = useRuntimeConfig();
const lightOpacity = computed(
  () =>
    (Math.abs(mouseX.value) > Math.abs(mouseY.value)
      ? Math.abs(mouseX.value)
      : Math.abs(mouseY.value)) * 100
);

const apiClient = useApi();

const loginCard = ref<HTMLElement>();

const cardTransformStyle = computed(() => ({
  transform:
    'rotateX(' +
    -mouseY.value * (5 * 5) +
    'deg) rotateY(' +
    mouseX.value * (5 * 5) +
    'deg)',
}));

const layerOneTransformStyle = computed(() => ({
  transform:
    'translateX(' +
    mouseX.value * 5 +
    'px) translateY(' +
    mouseY.value * 5 +
    'px)',
}));

const lightBackgroundStyle = computed(() => ({
  background: `linear-gradient(${
    angle.value
  }deg, rgba(255,255,255,0.1) 0%,rgba(255,255,255,0) ${
    lightOpacity.value < 0 ? lightOpacity.value * -1 : lightOpacity.value
  }%)`,
}));

const releaseInfo = config.public.wisionReleaseName || config.public.wisionRelease;

const loginMutation = useMutation(
  (params: { email: string; password: string }) =>
    withUseQuery(() => apiClient.auth.login(params.email, params.password)),
  {
    onSuccess: (res) => {
      if (res.access.Customers.length > 1) {
        customers.value = res.access.Customers
          .filter(customer => customer.CustomerID && customer.CustomerName)
          .map((customer) => ({
            id: customer.CustomerID,
            name: customer.CustomerName,
          }));

        userFullName.value = res.access.FullName;
      } else {
        navigateTo('/');
      }
    },
    onError: () => {
      toast(t('toast.invalidcredentials'), {
        autoClose: 2000,
        type: 'error',
        theme: 'dark',
      });
    },
  }
);

const handleMouseOver = (e: MouseEvent) => {
  const w = window.innerWidth;
  const h = window.innerHeight;

  height.value = h;
  pageY.value = e.pageY;

  const xVal = 0.5 - e.pageX / w;
  const yVal = 0.5 - e.pageY / h;

  const dy = e.pageY - h / 2;
  const dx = e.pageX - w / 2;

  const theta = Math.atan2(dy, dx);
  angle.value = (theta * 180) / Math.PI - 90;

  mouseX.value = xVal;
  mouseY.value = yVal;
};

onMounted(() => {
  window.addEventListener('mousemove', handleMouseOver);
});

const handleShowEmailLoginForm = (loginForm: boolean) => {
  if (spinning.value) return;
  spinning.value = true;
  showLoginForm.value = loginForm;
  window.removeEventListener('mousemove', handleMouseOver);
  spinIntervalId.value = setInterval(() => {
    if (mouseX.value > 7.2) {
      clearInterval(spinIntervalId.value);
      window.addEventListener('mousemove', handleMouseOver);
      mouseX.value = 0;
      spinning.value = false;
    }

    mouseX.value = mouseX.value + 0.1;
  }, 5);
};

const handleLogin = (email: string, password: string) =>
  loginMutation.mutate({
    email,
    password,
  });
</script>

<template>
  <video
    autoplay
    muted
    loop
    class="video"
  >
    <source
      src="https://images.wideco.winterland.dev/2023/09/earth_1.mp4"
      type="video/mp4"
    >
  </video>

  <div class="flex items-center justify-center h-screen p-4 text-white font-mono">
    <div class="w-96 sm:h-96 animated fadeIn z-30">
      <div
        ref="loginCard"
        :style="cardTransformStyle"
        class="p-8 bg-dark-widget rounded-lg h-[400px] w-full relative"
      >
        <div
          :style="lightBackgroundStyle"
          class="light"
        />
        <transition
          name="fade-fast"
          mode="out-in"
        >
          <div v-if="!showLoginForm">
            <div
              class="absolute top-[50%] translate-y-[-50%] w-full left-[50%] translate-x-[-50%] flex items-center flex-col"
            >
              <WisionLogo :width="64" />
              <p class="text-center text-gray-400 mt-5 text-sm w-[149px]">
                {{ $t('login.methods.select') }}
              </p>
            </div>
            <div
              class="grid grid-cols-3 items-center justify-center w-full gap-2 mt-2 absolute bottom-[48px] left-[50%] translate-x-[-50%] px-4"
            >
              <div
                class="grid grid-rows-[30px_20px] items-center rounded-lg w-full relative justify-items-center p-2 text-gray-600"
              >
                <BankIdIcon class="h-[30px] w-[30px] text-gray-600" />
                <p class="text-sm text-center w-full pt-2">
                  {{ $t('login.methods.bankid') }}
                </p>
              </div>
              <div
                class="grid grid-rows-[30px_20px] items-center rounded-lg hover:bg-gray-700 w-full justify-items-center cursor-pointer p-2"
                :style="layerOneTransformStyle"
                @click="handleShowEmailLoginForm(true)"
              >
                <AvatarIcon class="h-[30px] w-[30px]" />
                <p class="text-sm text-center w-full text-gray-300 pt-2 truncate">
                  {{ $t('login.methods.username') }}
                </p>
              </div>

              <div
                class="flex items-center rounded-lg p-2 w-full justify-center mb-2"
              >
                <MoreIcon class="text-gray-600" />
              </div>
            </div>
          </div>
          <div
            v-else-if="!customers"
            class="h-full flex items-center relative"
          >
            <ArrowIcon
              :direction="Direction.Left"
              class="absolute top-2 left-2 cursor-pointer"
              @click="handleShowEmailLoginForm(false)"
            />
            <EmailLoginForm
              :is-loading="loginMutation.isLoading.value"
              @login="handleLogin"
            />
          </div>
          <SelectAccount
            v-else
            :user-full-name="userFullName ?? ''"
            :accounts="customers ?? []"
          />
        </transition>
        <div
          class="text-xs text-center text-gray-500 absolute bottom-2 left-[50%] translate-x-[-50%]"
        >
          {{ releaseInfo }}
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

.light {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
