<script setup lang="ts">
import { computed, type PropType, ref } from 'vue';
import {
  SelectWithSearch,
  CustomButton,
  WisionLogo,
  LoadingSpinner,
  Combobox
} from '@wision/ui';
import { useMutation } from '@tanstack/vue-query';
import { withUseQuery } from '@wision/api';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';

const apiClient = useApi();
const { t } = useI18n();

const props = defineProps({
  userFullName: {
    type: String,
    required: true,
  },
  accounts: {
    type: Array as PropType<{ id: number; name: string }[]>,
    required: true,
  },
});

const updateContextMutation = useMutation(
  (organization: number) =>
    withUseQuery(() => apiClient.user.updateCurrentOrganization(organization)),
  {
    onSuccess: () => {
      window.location.href = '/';
    },
    onError: () => {
      toast(t('common.somethingwentwrong'), {
        autoClose: 2000,
        type: 'error',
        theme: 'dark',
      });
    },
  }
);

const selectedAccount = ref();

const options = computed(() =>
  props.accounts.map((account) => ({
    label: account.name,
    value: account.id,
  }))
);

const handleSelectAccount = () => {
  if (selectedAccount.value)
    updateContextMutation.mutate(selectedAccount.value);
};
</script>

<template>
  <div>
    <WisionLogo
      :width="64"
      class="mt-5"
    />
    <h2 class="text-center text-2xl font-thin mt-2">
      {{ $t('login.welcome') }}
    </h2>
    <p class="text-center">
      {{ userFullName }}
    </p>
    <div class="mt-10 flex flex-col gap-4">
      <div class="z-[400]">
        <p class="text-gray-300 font-thin">
          {{ $t('login.account.title') }}
        </p>
        <Combobox
          v-model="selectedAccount"
          :options="options"
          :place-holder="$t('login.account.select')"
        />
      </div>
      <CustomButton
        class="w-full z-40"
        :types="['border', 'auto-width']"
        @click="handleSelectAccount"
      >
        <LoadingSpinner
          v-if="updateContextMutation.isLoading.value"
          class="h-4"
        />
        <div v-else>
          {{ $t('login.account.continue') }}
        </div>
      </CustomButton>
    </div>
  </div>
</template>
