<script setup lang="ts">
import Login from '@/features/login/Login.vue';

definePageMeta({
});

const session = useSession();

if (session.get.value) navigateTo('/dashboard');
</script>

<template>
  <div>
    <NuxtLayout
      name="login"
      :title="$t('pages.maintitle') + $t('pages.login.title')"
    >
      <Login />
    </NuxtLayout>
  </div>
</template>

