<template>
  <svg width="57px" height="54px" viewBox="0 0 57 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-182.000000, -404.000000)" stroke="#FFFFFF" stroke-width="3.0375">
        <g transform="translate(183.600000, 406.321782)">
          <ellipse cx="26.83125" cy="26.8238707" rx="26.83125" ry="26.8238707"></ellipse>
          <ellipse cx="26.83125" cy="19.7383199" rx="11.64375" ry="11.6405476"></ellipse>
          <path d="M7.27163674,45.1855693 C10.2116099,36.9087205 18.0456159,31.3788676 26.83125,31.3788676 C35.6168841,31.3788676 43.4508901,36.9087205 46.3908633,45.1855693"></path>
        </g>
      </g>
    </g>
  </svg>
</template>
